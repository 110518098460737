import { default as firstrunUassNJeC4EMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45password1R2lUuunAkMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/forgot-password.vue?macro=true";
import { default as index7IciKVxhnuMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/index.vue?macro=true";
import { default as loginZbmW6rrPydMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45team6Yh6fUd52QMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_93W0qFCoB9dGMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addQhdku03b3PMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_93WjB55kkxLBMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as indexTF54T4Y7G3Meta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_93bv16oYnWU0Meta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_93uKVoX0ctMvMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as indexrwoykCdFewMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/index.vue?macro=true";
import { default as pendinggXBbb0XCZQMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_93l03Yc0lEdVMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_93sjHvI6xyk0Meta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexJE7wXzSW4JMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as add7szRFG4npeMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_93XJifSnPKO9Meta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as indexwTW7FLzALlMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93s8gvuhu2ACMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as add63CeQqKJ7cMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93AysFqnXaTaMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexlgHRfkdtkpMeta } from "/home/forge/sandbox.jmi.cloud/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: "firstrun",
    path: "/firstrun",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password1R2lUuunAkMeta || {},
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginZbmW6rrPydMeta || {},
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "meet-the-team",
    path: "/meet-the-team",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: "move-notifications-index-filter",
    path: "/move-notifications/:filter?",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: "movers-add",
    path: "/movers/add",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: "movers-edit-id",
    path: "/movers/edit/:id()",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "movers",
    path: "/movers",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-add-step",
    path: "/moves/add/:step?",
    meta: _91_91step_93_93bv16oYnWU0Meta || {},
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-edit-id-step",
    path: "/moves/edit/:id()/:step?",
    meta: _91_91step_93_93uKVoX0ctMvMeta || {},
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves",
    path: "/moves",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-pending",
    path: "/moves/pending",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: "moves-view-id",
    path: "/moves/view/:id()",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "moves-void-id-step",
    path: "/moves/void/:id()/:step?",
    meta: _91_91step_93_93sjHvI6xyk0Meta || {},
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-void",
    path: "/moves/void",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-add",
    path: "/partner-users/add",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-edit-id",
    path: "/partner-users/edit/:id()",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "partner-users",
    path: "/partner-users",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93s8gvuhu2ACMeta || {},
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "properties-add",
    path: "/properties/add",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: "properties-edit-id",
    path: "/properties/edit/:id()",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("/home/forge/sandbox.jmi.cloud/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]